import {useState, useEffect } from 'react'
import { ethers, BigNumber } from 'ethers';
import { Box, Button, Flex, Input, Text, Image } from '@chakra-ui/react';
import ETHER from './contract/abi.json';

const ETHERAddress = "0x335729929eAdff1Dd44a861Dd915D4FC2Df30C5D"

const MainMint = ({ accounts, setAccounts }) => {
    const [mintAmount, setMintAmount] = useState(1);
    const isConnected = Boolean(accounts[0]);
    async function connectAccount() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }
    }
        async function handleMint() {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(
                    ETHERAddress,
                    ETHER,
                    signer
                )
                try {
                    const response = await contract.mint(BigNumber.from(mintAmount),{
                        value: ethers.utils.parseEther((0.001*mintAmount).toString())
                    });
                    console.log ('response: ', response);
                } catch (err){
                    alert(JSON.stringify(err))
                console.log("error: ", err)
            }
            }
        }

        const handleDecrement = () => {
            if (mintAmount <= 1) return;
            setMintAmount(mintAmount - 1);
        };
    
        const handleIncrement = () => {
            console.log("quick")
            if (mintAmount >= 10) return;
            setMintAmount(mintAmount + 1);
        };
        const maxAmount = () => {
            console.log("quick")
            setMintAmount(10);
        };
        return (
            <Flex justify="center" alight="center" height="100vh" paddingBottom="150px">
                <Box width="520">
                {isConnected ? (
                    <div className='moveDown'>
                        
                        <Flex align="center" justify="center">
                            
                            <Button
                            backgroundColor="#9aead4"
                            borderRadius="6.666666666666667vh"
                            boxShadow="0px 1px 1px 1px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="myFont"
                            padding="15px 25px"
                            fontSize="3.3333333333333335vh"
                            onClick={handleDecrement}
                            border="none"
                            top="125%"
                            left="15%"
                            >
                                -
                                </Button>
                            <Input
                            readOnly
                            fontFamily="inherit"
                            width="8.933333333333334vh"
                            height="5.333333333333333vh"
                            position="absolute"
                            transform="translate(-50%,-50%)"
                            top="54%"
                            left="50%"
                            textAlign="center"
                            paddingLeft="2vh"
                            type="number"
                            value={mintAmount}
                            fontSize="2.8vh"
                            placeholder='1'
                            />
                            <Button
                            backgroundColor="#9aead4"
                            borderRadius="6.666666666666667vh"
                            boxShadow="0px 1px 1px 1px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="myFont"
                            padding="15px 22px"
                            fontSize="3.3333333333333335vh"
                            onClick={handleIncrement}
                            border="none"
                            top="125%"
                            left="41%"
                            >    
                            +
                            </Button>
                            <Button
                            className='moveRight'
                            backgroundColor="#9aead4"
                            borderRadius="10vh"
                            boxShadow="0px 1px 1px 1px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="myFont"
                            padding="12px 14px"
                            fontSize="2.933333333333333vh"
                            onClick={maxAmount}
                            border="none"
                            top="125%"
                            left="13%"
                            > 
                            Max
                            </Button>
                            </Flex>
                            <Button
                            backgroundColor="#9aead4"
                            borderRadius="10vw"
                            boxShadow="0px 2px 1px #0F0F0F"
                            color="white"
                            currsor="pointer"
                            fontFamily="serif"
                            padding="3.2vh 2.505600000000001vh"
                            position="absolute"
                            transform="translate(-50%,-50%)"
                            cursor="pointer"
                            fontSize="3.066666666666667vh"
                            onClick={handleMint}
                            bottom="0px"
                            top="133%"
                            left="50%"
                            border="none"
                            >
                                Mint
                                </Button>
                                </div>
                                


                ) : (
                    <Text
                    marginTop="70px"
                    fontSize="30px"
                    letterSpacing="-5.55"
                    fontFamily="myFont"
                    textShadow="0 3px #0F0F0F"
                    color="#fad121"

                    >
                    </Text>
                )}
                                {isConnected ? (
                <Box margin="0 15"></Box>

            ) : (
                <Button
                    backgroundColor="#9aead4"
                    borderRadius="1vw"
                    boxShadow="0px 1px 3px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="5px 15px"
                    position="absolute"
                    top="53%"
                    left="50%"
                    transform="translate(-50%,-50%)"
                    fontSize="4.8vh"
                    fontWeight={200}
                    border="none"
                    onClick={connectAccount}
                    webkit-box-align="fixed"
                    height="6.3vh"
                    width="41vh"
                >
                    Connect wallet

                </Button>
            )}
                </Box> 
            </Flex>
            
        )
        
};


export default MainMint;
